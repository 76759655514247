<template>
  <v-container>
    <iframe
      id="ssoFrame"
      ref="ssoFrame"
      :src="ssoUrl"
      width="100%"
      frameborder="0"
      height="150px"
      scrolling="no"
    />
  </v-container>
</template>

<script>
import { computed, ref, onMounted } from "vue-demi";
import jwtDecode from "jwt-decode";

export default {
  name: "AppLoginSSO",
  props: ["feathersClient", "appGroups", "authStore"],
  setup(props, { emit }) {
    const ssoFrame = ref();
    const ssoUrlObj = new URL(process.env.VUE_APP_SSO_URL);
    // Append query params to the SSO URL
    if (props.appGroups && Array.isArray(props.appGroups)) {
      ssoUrlObj.searchParams.append(
        "appGroups",
        JSON.stringify(props.appGroups)
      );
    }
    // Define the SSO URL
    const ssoUrl = computed(() => ssoUrlObj.href);
    // Set up iframe event listener variables
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    const messageListener = async (event) => {
      if (event.origin === ssoUrlObj.origin && event.data.type === "ssoLogin") {
        emit("ssoLogin", event.data?.accessToken?.length);
        const authRes = await props.authStore.authenticate({
          strategy: "jwt",
          accessToken: event.data.accessToken,
        });
        const authCheck = await props.feathersClient
          .service("auth-check")
          .find()
          .catch((err) => {
            console.log("authCheck err", err);
            return false;
          });
        if (authRes && authCheck) {
          // Emit the authentication event
          emit("login", {
            accessToken: event.data.accessToken,
            user: jwtDecode(event.data.accessToken).azureUser,
          });
        }
        // Execute afterLogin tasks
        await props.authStore.afterLogin();
      }
    };

    onMounted(() => {
      // Start event listener once iframe is mounted in the DOM
      eventer(messageEvent, messageListener);
    });

    return {
      ssoFrame,
      ssoUrl,
    };
  },
};
</script>
