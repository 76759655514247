<template>
  <v-dialog :value="true" persistent max-width="400">
    <v-card>
      <v-container>
        <v-row>
          <v-col class="text-center">
            <AppLoginSSO
              :feathersClient="feathersClient"
              :appGroups="appGroups"
              :authStore="authStore"
              @login="onLogin"
              v-show="!authStore.isAuthenticated"
            />
            <LoadingContainer
              v-if="authStore.isAuthenticated"
              :loading="!authStore.dataLoaded"
              label="Loading Data..."
            >
              <v-card-text>
                <p class="text-h5 text--primary mb-0">Redirecting...</p>
              </v-card-text>
            </LoadingContainer>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppLoginSSO from "./AppLoginSSO.vue";
import { api } from "../utils/feathers-api";
import { appGroups } from "../utils/variables";
import { useAuth } from "../store/auth.pinia";
import LoadingContainer from "./LoadingContainer.vue";

export default {
  name: "LoginPage",
  components: {
    AppLoginSSO,
    LoadingContainer,
  },
  setup() {
    const authStore = useAuth();

    const onLogin = (result) => {
      console.log("AUTHED!!!", result);
    };

    return {
      appGroups,
      feathersClient: api,
      onLogin,
      authStore,
    };
  },
};
</script>
